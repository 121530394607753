import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import urlJoin from "url-join";

import Card from "../components/Card";
import SEO from "../components/SEO";
import { Heading, Button } from "../components/Primitives";
import { StyledForm } from "../components/StyledForm";

const Contact = ({
  data: {
    site: {
      siteMetadata: { title, siteUrl, card },
    },
  },
}) => (
  <Fragment>
    <SEO
      title={`Contact | ${title}`}
      description="Contact form."
      image={urlJoin(siteUrl, card)}
      url={urlJoin(siteUrl, "/contact/")}
    />
    <Card>
      <Heading as="h1" fontSize="2.35em" my="var(--rhythm)">
        Contact
      </Heading>
      <p>
        Fill this form if you want to ask me questions, suggest edits, or
        talk about the Milwaukee Bucks. You can also use it for job
        opportunities/freelance work.
      </p>
      <p>If you are a bot, go away.</p>
      <StyledForm name="devblog" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="devblog" />
        <label
          htmlFor="email"
          style={{ marginBottom: "calc(var(--rhythm) / 4)" }}
        >
          <strong>Email:</strong>{" "}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          aria-label="Email"
          required
        />
        <label
          htmlFor="subject"
          style={{ marginBottom: "calc(var(--rhythm) / 4)" }}
        >
          <strong>Subject:</strong>{" "}
        </label>
        <input
          type="text"
          name="subject"
          id="subject"
          aria-label="Subject"
          required
        />
        <label
          htmlFor="message"
          style={{ marginBottom: "calc(var(--rhythm) / 4)" }}
        >
          <strong>Message:</strong>{" "}
        </label>
        <textarea
          name="message"
          id="message"
          rows="7"
          aria-label="Message"
          required
        />
        <Button variant="green" type="submit" width="150px">
          Send
        </Button>
      </StyledForm>
    </Card>
  </Fragment>
);

Contact.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        card
        title
      }
    }
  }
`;

export default Contact;
