import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  padding: var(--rhythm);
  width: 100%;
  background-color: var(--bg-content);
  color: var(--color);
  border-top: 1px solid var(--bg-border);
  border-left: 1px solid var(--bg-border);
  border-right: 1px solid var(--bg-border);
  border-bottom: 3px solid var(--bg-border);
  border-radius: 5px;
  box-shadow: 0 0 4px 2px var(--card-shadow);

  @media screen and (max-width: 576px) {
    padding: calc(var(--rhythm) / 2);
  }
`;
const Card = ({ marginTop, children }) => (
  <StyledContainer className="typographic-grids" marginTop={marginTop}>
    {children}
  </StyledContainer>
);

Card.propTypes = {
  marginTop: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  marginTop: null,
};

export default Card;
