import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  input,
  textarea {
    color: var(--color);
    margin-bottom: calc(var(--rhythm) / 2);
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    padding: calc(var(--rhythm) / 4);
  }

  input::placeholder,
  textarea::placeholder {
    color: hsl(0deg 0% 58%);
  }
`;
